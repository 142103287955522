import React from "react";
import { bool, func, object, shape, string } from "prop-types";
import { useIntl } from "react-intl";
import { formatCatClass } from "../../../utils/commonUtils";
import GreenLeafTag from '../../../atoms/greenLeafTag/GreenLeafTag';
import "./productTileListView.scss";
import { productTileDataLocator } from "../../addToCartTile/dataLocators";

const MobileLayout = ({
  catClass,
  productName,
  productUrl,
  handleAnchorClick,
  footerSection,
  specificationSection,
  getProductImage,
  ariaLabel,
  isUnavailable,
  greenLeafDetails
}) => {
  const intl = useIntl();
  const { isGreenLeaf } = greenLeafDetails || {};

  const imageSection = () => {
    return (
      <>
        <div
          data-testid={
            productTileDataLocator.productTile_productFavoriteIndicator
          }
          className="producttile__favourite"
        >
        </div>
        <div className="producttile__img-title">
          <a
            className="producttile__top"
            href={productUrl}
            onClick={handleAnchorClick}
            {...ariaLabel}>
            {getProductImage()}
          </a>
          <div>
            <a
              className="producttile__top"
              href={productUrl}
              onClick={handleAnchorClick}
              {...ariaLabel}>
              <h3 className="producttile__catclass eyebrow">{`${intl.formatMessage({
                  id: 'homepage:cat-class'
              })} ${formatCatClass(catClass)}`}</h3>
              <h4 className="producttile__productname">{productName}</h4>
            </a>
            {isGreenLeaf && <GreenLeafTag greenLeafData={greenLeafDetails?.greenLeafData} />}
          </div>
        </div>
        {isUnavailable && (
          <div
            className="producttile__unavailable"
            aria-live={`${productName} is ${intl.formatMessage({
              id: "product-tile-unavailable",
            })}`}
          >
            {intl.formatMessage({ id: "product-tile-unavailable" })}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="mobile-container">
      <div className="image-container">{imageSection()}</div>
      <div className="description-container">{specificationSection()}</div>
      <div className="footer-container">{footerSection()}</div>
    </div>
  );
};

export default React.memo(MobileLayout);

MobileLayout.defaultProps = {
  catClass: "",
  productName: "",
  productUrl: "",
  handleAnchorClick: () => {},
  isUnavailable: false,
  footerSection: ()=>{},
  specificationSection: ()=>{},
  getProductImage: ()=>{},
  ariaLabel: {},
  greenLeafDetails: shape({
    isGreenLeaf: false,
    greenLeafData: ""
  })
};

MobileLayout.propTypes = {
  catClass: string,
  productName: string,
  productUrl: string,
  handleAnchorClick: func,
  isUnavailable: bool,
  footerSection: func,
  specificationSection: func,
  getProductImage: func,
  ariaLabel: object,
  greenLeafDetails: shape({
    isGreenLeaf: bool,
    greenLeafData: string
  })
};
