import { func, number, oneOfType, shape, string } from 'prop-types';
import React from 'react';
import BaseProductTile from '../BaseProductTile';
import DateSelector from '../atoms/dateSelector/DateSelector';

const ChangeDatesGrt24Hrs = ({
    tileDetails,
    productRatesDailyWeeklyMonthly,
    accountNumber,
    handleAnchorClick,
    handleRedirectClick,
    showAvailableDates,
    footerTileClassName,
    footerTileRef,
    ...restProps
}) => {
    const { availableDate, selectDates } = showAvailableDates || {};
    const footerContent = () => {
        return (
            <DateSelector
                availabilityDate={availableDate}
                selectDates={selectDates}
                productName={tileDetails?.producttitle}
                btnCustomClass={'productTile-hover'}></DateSelector>
        );
    };
    return (
        <BaseProductTile
            footerTileClassName={footerTileClassName}
            footerTileRef={footerTileRef}
            productUrl={tileDetails?.producturl}
            productImg={tileDetails?.productimageurl}
            catClass={tileDetails?.catclass}
            productName={tileDetails?.producttitle}
            productRatesDailyWeeklyMonthly={productRatesDailyWeeklyMonthly}
            accountNumber={accountNumber}
            footerContent={footerContent}
            handleAnchorClick={handleAnchorClick}
            handleRedirectClick={handleRedirectClick}
            productAltText={tileDetails.productAltText}
            specifications={tileDetails?.specifications}
            greenLeafDetails={{ isGreenLeaf: tileDetails?.isgreenleaf, greenLeafData: tileDetails?.greenleafdata }}
            {...restProps}></BaseProductTile>
    );
};

export default React.memo(ChangeDatesGrt24Hrs);

ChangeDatesGrt24Hrs.defaultProps = {
    tileDetails: {
        catclass: '',
        producttitle: '',
        productimageurl: '',
        producturl: '#',
        showonlinecatalog: 'YES',
        disableaddtocartoption: 'NO',
        categoryname: '',
        catsku: '',
        catid: '',
        metaTitle: '',
        metaDescription: '',
        productAltText: ''
    },
    productRatesDailyWeeklyMonthly: {},
    handleAnchorClick: () => {},
    handleRedirectClick: () => {},
    footerTileClassName: '',
    footerTileRef: () => {}
};

ChangeDatesGrt24Hrs.propTypes = {
    tileDetails: shape({
        catclass: oneOfType([number, string]),
        producttitle: string,
        productimageurl: string,
        producturl: string,
        showonlinecatalog: string,
        disableaddtocartoption: string,
        categoryname: string,
        catsku: oneOfType([number, string]),
        catid: oneOfType([number, string]),
        metaDescription: string,
        metaTitle: string,
        productAltText: string
    }),
    productRatesDailyWeeklyMonthly: shape({
        daily: string,
        monthly: string,
        weekly: string,
    }),
    handleAnchorClick: func,
    handleRedirectClick: func,
    footerTileClassName: string,
    footerTileRef: func
};
