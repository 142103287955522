import React from 'react';
import { shape, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { currencyToLocale } from '../../../../utils/commonUtils';
import './priceRange.scss';

const PriceRange = props => {
    const { pricesObj } = props;
    let { daily, monthly, weekly } = pricesObj || {};
    const intl = useIntl();

    daily = parseFloat(daily || 0);
    monthly = parseFloat(monthly || 0);
    weekly = parseFloat(weekly || 0);

    return (
        <div className="pricing-container">
            <div className="price-item">
                <div className="price-body">{daily ? currencyToLocale(daily) : '-'}</div>
                <div className="eyebrow">{intl.formatMessage({ id: 'plp:dayPrice' })}</div>
            </div>
            <div className="price-item">
                <div className="price-body">{weekly ? currencyToLocale(weekly) : '-'}</div>
                <div className="eyebrow">{intl.formatMessage({ id: 'plp:weekPrice' })}</div>
            </div>
            <div className="price-item">
                <div className="price-body">{monthly ? currencyToLocale(monthly) : '-'}</div>
                <div className="eyebrow">{intl.formatMessage({ id: 'plp:fourWeekPrice' })}</div>
            </div>
        </div>
    );
};
export default React.memo(PriceRange);

PriceRange.propTypes = {
    pricesObj: shape({
        daily: string,
        monthly: string,
        weekly: string
    }),
};
PriceRange.defaultProps = {
    pricesObj: {
        daily: '0',
        monthly: '0',
        weekly: '0'
    },
};
