import React, { useState } from "react";
import { bool, func, number, oneOfType, shape, string } from "prop-types";
import { useIntl } from "react-intl";
import useMedia from "../../../../../hooks/useMedia";
import { useCheckAuthorityType } from "../../../../../hooks/useCheckUser";
import DesktopLayout from "./DesktopLayout";
import TabletLayout from "./TabletLayout";
import MobileLayout from "./MobileLayout";
import Favorites from "../atoms/favorites/Favorites";
import ProductImage from "../../../atoms/productImage/ProductImage";
import FavouritesButton from "../../../../favorites/modules/favouritesButton/FavouritesButton";
import DefaultViewNoImage from "../atoms/noImageView/NoImageView";
import PriceRange from "../atoms/priceRange/PriceRange";
import HigherFeeWarningAlert from "../../../atoms/higherFeeWarningAlert/HigherFeeWarningAlert";
import "./productTileListView.scss";
import { MEDIA_TYPE } from "../../../../../constants/screenConstants";
import { IMAGE_HEIGHT } from "../constants";
import { AUTHORITY_TYPE } from "../../../constants";

const ProductTileListView = ({
  catClass,
  accountNumber,
  productName,
  productUrl,
  handleAnchorClick,
  productImg,
  handleRedirectClick,
  productAltText,
  productRatesDailyWeeklyMonthly,
  isAvailableWithWarning,
  footerTileClassName,
  isFavorite,
  nativeLazyLoad,
  footerTileRef,
  footerContent,
  isUnavailable,
  specifications,
  greenLeafDetails
}) => {
  const intl = useIntl();
  const authorityType = useCheckAuthorityType();
  const isP2P = authorityType === AUTHORITY_TYPE.P2P;
  const mediaType = useMedia();
  const [brokenImage, setBrokenImage] = useState(false);
  const ariaLabel = isUnavailable
  ? {
    "aria-label": `${intl.formatMessage({
      id: "product-tile-unavailable",
    })} ${intl.formatMessage({ id: "common:for" })} ${productName}`,
  }
  : {
    "aria-describedby": `${catClass}-price-description`,
  };
  const specificationBody = specifications?.match(/<li>.*?<\/li>/g)?.slice(0, 3)?.join('') || '';

  const footerSection = () => {
    return (
      <>
        {!isUnavailable && (
          <PriceRange pricesObj={productRatesDailyWeeklyMonthly} />
        )}
        {isAvailableWithWarning && !!productRatesDailyWeeklyMonthly?.daily && isP2P && (
          <HigherFeeWarningAlert className="mb_1" productName={productName} />
        )}
        <div
          className={`${footerTileClassName} producttile__footer`}
          ref={footerTileRef}
        >
          {footerContent()}
          {isP2P && (
            <FavouritesButton
              isFavorite={isFavorite}
              sku={catClass}
              qty={1}
              favoriteClasses={"prod_tile_fav_btn"}
              productName={productName}
            />
          )}
          {!isP2P && (
            <Favorites
              sku={catClass}
              accountNumber={accountNumber}
              showFavoriteTitle={true}
              productName={productName}
              favoriteClasses={"prod_tile_fav_btn"}
            />
          )}
        </div>
      </>
    );
  };

  const specificationSection = () => {
    return (
      specificationBody ?
      <div className="producttile__specification">
        <p className="specification__header ">{intl.formatMessage({id: "product-tile-specifications"})}</p>
        <div className="specification__body" dangerouslySetInnerHTML={{ __html:  `<ul>${specificationBody}</ul>`}} />
      </div>:null
    );
  };

  const getProductImageForUnavailable = () => 
    productImg ? (
      <div className="producttile__img" onClick={handleRedirectClick}>
        <img
          src={productImg}
          alt={productName}
          height={IMAGE_HEIGHT}
          className="unavailable-img"
        />
        {mediaType !== MEDIA_TYPE.MOBILE && (
          <div
            className="producttile__unavailable"
            aria-live={`${productName} is ${intl.formatMessage({
              id: "product-tile-unavailable",
            })}`}
          >
            {intl.formatMessage({ id: "product-tile-unavailable" })}
          </div>
        )}
      </div>
    ) : (
      <div className="producttile__img-unavailable">
        <DefaultViewNoImage />
        {mediaType !== MEDIA_TYPE.MOBILE && (
          <div
            className="producttile__unavailable"
            aria-live={`${productName} is ${intl.formatMessage({
              id: "product-tile-unavailable",
            })}`}
          >
            {intl.formatMessage({ id: "product-tile-unavailable" })}
          </div>
        )}
      </div>
    );

  const getProductImageForAvailable = () => (
    <div
      className={productImg && !brokenImage ? "producttile__img" : "producttile__img producttile__img-noimage"}
      onClick={productImg && !brokenImage ? handleRedirectClick : undefined}
    >
      <ProductImage
        url={productImg}
        width="auto"
        height={IMAGE_HEIGHT}
        nativeLazyLoad={nativeLazyLoad ? "lazy" : undefined}
        name={productAltText || productName}
        setIsError={setBrokenImage}
        defaultViewCardType={'card'} 
        tabIndex={-1}
      />
    </div>
  );

  const getProductImage = () => {
    if(isUnavailable) {
      return getProductImageForUnavailable();
    }
    return getProductImageForAvailable();
  }

  const getLayouts = () => {
    switch (mediaType) {
      case MEDIA_TYPE.MOBILE:
        return (
          <MobileLayout
            catClass={catClass}
            productName={productName}
            productUrl={productUrl}
            handleAnchorClick={handleAnchorClick}
            footerSection={footerSection}
            specificationSection={specificationSection}
            getProductImage={getProductImage}
            ariaLabel={ariaLabel}
            isUnavailable={isUnavailable}
            greenLeafDetails={greenLeafDetails}
          />
        );
      case MEDIA_TYPE.TABLET:
        return (
          <TabletLayout
            catClass={catClass}
            productName={productName}
            productUrl={productUrl}
            handleAnchorClick={handleAnchorClick}
            footerSection={footerSection}
            specificationSection={specificationSection}
            getProductImage={getProductImage}
            ariaLabel={ariaLabel}
            greenLeafDetails={greenLeafDetails}
          />
        );
      case MEDIA_TYPE.DESKTOP:
        return (
          <DesktopLayout
            catClass={catClass}
            productName={productName}
            productUrl={productUrl}
            handleAnchorClick={handleAnchorClick}
            footerSection={footerSection}
            specificationSection={specificationSection}
            getProductImage={getProductImage}
            ariaLabel={ariaLabel}
            greenLeafDetails={greenLeafDetails}
          />
        );
      default:
        return null;
    }
  };

  return <>{getLayouts()}</>;
};

export default React.memo(ProductTileListView);

ProductTileListView.defaultProps = {
  catClass: "",
  accountNumber: "",
  productName: "",
  productUrl: "",
  handleAnchorClick: () => {},
  productImg: "",
  handleRedirectClick: () => {},
  productAltText: "",
  productRatesDailyWeeklyMonthly: {},
  footerTileClassName: "",
  footerTileRef: () => {},
  isFavorite: false,
  footerContent: () => {},
  isUnavailable: false,
  isAvailableWithWarning: false,
  nativeLazyLoad: "lazy",
  specifications: "",
  greenLeafDetails: shape({
    isGreenLeaf: false,
    greenLeafData: ""
  })
};

ProductTileListView.propTypes = {
  catClass: string,
  accountNumber: oneOfType([number, string]),
  productName: string,
  productUrl: string,
  handleAnchorClick: func,
  productImg: string,
  handleRedirectClick: func,
  productAltText: string,
  productRatesDailyWeeklyMonthly: shape({
    daily: string,
    monthly: string,
    weekly: string,
  }),
  footerTileClassName: string,
  footerTileRef: func,
  isFavorite: bool,
  footerContent: func,
  isUnavailable: bool,
  isAvailableWithWarning: bool,
  nativeLazyLoad: oneOfType(['lazy', 'eager', 'auto']),
  specifications: string,
  greenLeafDetails: shape({
    isGreenLeaf: bool,
    greenLeafData: string
  })
};
