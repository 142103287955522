import React from 'react';
import { bool, func } from 'prop-types';
import { useIntl } from 'react-intl';
import AlertModal from '../../modules/modals/alertModal';
import InfoIcon28 from '../../../../resources/images/info_28.svg';
import './greenLeafTag.scss';
import { greenLeafDataLocator } from './dataLocator';
import { ENV_CONFIG } from '../../../../constants/envConfig';

const GreenLeafInfoModal = ({ isInfoModalOpen, onCloseHandler }) => {
    const intl = useIntl();
    const { greenleafmodaltitle, greenleafmodaldesc, greenleafmodallinktitle, greenleafmodallinkurl, href } =
        ENV_CONFIG.GREEN_LEAF_CONFIGS || {};

    const greenLeafInfoModalContent = () => {
        return (
            <>
                <div
                    dangerouslySetInnerHTML={{
                        __html: greenleafmodaldesc || intl.formatMessage({ id: 'green-leaf-info-modal-body' })
                    }}
                />
                <a href={href || '#'} className="popup__link">
                    {greenleafmodallinktitle || intl.formatMessage({ id: 'green-leaf-info-modal-link-content' })}
                </a>
            </>
        );
    };

    return (
        <AlertModal
            isModalOpen={isInfoModalOpen}
            handleCloseModal={onCloseHandler}
            title={greenleafmodaltitle || intl.formatMessage({ id: 'green-leaf-info-modal-title' })}
            content={greenLeafInfoModalContent()}
            icon={<InfoIcon28 aria-hidden="true" tabIndex="-1" />}
            isWarning
            alertClassname="green_leaf_info_modal"
            titleDataLocator={greenLeafDataLocator.green_leaf_info_modal_testId}
        />
    );
};

export default React.memo(GreenLeafInfoModal);

GreenLeafInfoModal.defaultProps = {
    isInfoModalOpen: false,
    onCloseHandler: () => {}
};

GreenLeafInfoModal.propTypes = {
    isInfoModalOpen: bool,
    onCloseHandler: func
};
