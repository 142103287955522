import React, { useState } from "react";
import { func, oneOfType, string, number, shape, bool } from "prop-types";
import { useIntl } from "react-intl";
import { useCheckAuthorityType } from "../../../../hooks/useCheckUser";
import { useFavouritesState } from "../../../favorites/context";
import FavouritesButton from "../../../favorites/modules/favouritesButton/FavouritesButton";
import HigherFeeWarningAlert from "../../atoms/higherFeeWarningAlert/HigherFeeWarningAlert";
import ProductTileListView from "./productTileListView/ProductTileListView";
import GreenLeafTag from '../../atoms/greenLeafTag/GreenLeafTag';
import { AUTHORITY_TYPE, PRODUCT_TILE_VARIANT } from "../../constants";
import Favorites from "./atoms/favorites";
import { productTileDataLocator } from "../addToCartTile/dataLocators";
import { currencyToLocale, formatCatClass, isGreenLeafEnabled } from "../../utils/commonUtils";
import DefaultViewNoImage from "./atoms/noImageView/NoImageView";
import { IMAGE_HEIGHT } from "./constants";
import "./baseproducttile.scss";

const BaseProductTile = ({
  productImg,
  catClass,
  productName,
  productUrl,
  accountNumber,
  footerContent,
  handleAnchorClick,
  handleRedirectClick,
  productAltText,
  isAvailableWithWarning,
  footerTileRef,
  footerTileClassName,
  nativeLazyLoad = "loading" in window.HTMLImageElement.prototype,
  productRatesDailyWeeklyMonthly,
  variant,
  specifications,
  greenLeafDetails
}) => {
  const intl = useIntl();
  const [{ favoriteProducts }] = useFavouritesState();
  const authorityType = useCheckAuthorityType();
  const isP2P = authorityType === AUTHORITY_TYPE.P2P;
  const isProductFavorite = (productCatClass) => {
    const favoriteProduct = favoriteProducts.find(
      (product) => product.sku === productCatClass
    );
    return favoriteProduct?.isFavorite;
  };
  const [brokenImage, setBrokenImage] = useState(false);
  const { isGreenLeaf, greenLeafData } = greenLeafDetails || {};
  const { daily } = productRatesDailyWeeklyMonthly || {};

  return (
    <>
      {variant === PRODUCT_TILE_VARIANT.LIST_VIEW && (
        <div className="producttile">
          <ProductTileListView
            catClass={catClass}
            accountNumber={accountNumber}
            productName={productName}
            productImg={productImg}
            nativeLazyLoad={nativeLazyLoad}
            handleRedirectClick={handleRedirectClick}
            productRatesDailyWeeklyMonthly={productRatesDailyWeeklyMonthly}
            isAvailableWithWarning={isAvailableWithWarning}
            footerTileClassName={footerTileClassName}
            footerTileRef={footerTileRef}
            isFavorite={isProductFavorite(catClass)}
            footerContent={footerContent}
            productUrl={productUrl}
            handleAnchorClick={handleAnchorClick}
            productAltText={productAltText}
            specifications={specifications}
            greenLeafDetails={greenLeafDetails}
          />
        </div>
      )}
      {variant === PRODUCT_TILE_VARIANT.GRID_VIEW && (
        <div className="producttile">
          <div
            data-testid={
              productTileDataLocator.productTile_productFavoriteIndicator
            }
            className="producttile__favourite"
          >
            {!isP2P && (
              <Favorites
                sku={catClass}
                accountNumber={accountNumber}
                showFavoriteTitle={false}
                productName={productName}
              />
            )}
          </div>
          <div>
            <a
              className="producttile__top"
              href={productUrl}
              onClick={handleAnchorClick}
              aria-describedby={`${catClass}-price-description`}
            >
              {productImg && !brokenImage ? (
                nativeLazyLoad ? (
                  <div
                    className="producttile__img"
                    onClick={handleRedirectClick}
                  >
                    <img
                      alt={productAltText ? productAltText : productName}
                      src={productImg}
                      loading="lazy"
                      height={IMAGE_HEIGHT}
                      onError={() => {
                        setBrokenImage(true);
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className="producttile__img"
                    onClick={handleRedirectClick}
                  >
                    <img
                      alt={productAltText ? productAltText : productName}
                      src={productImg}
                      height={IMAGE_HEIGHT}
                      onError={() => {
                        setBrokenImage(true);
                      }}
                    />
                  </div>
                )
              ) : (
                <DefaultViewNoImage />
              )}
              <div>
                <h3 className="producttile__catclass eyebrow">{`${intl.formatMessage(
                  {
                    id: "homepage:cat-class",
                  }
                )} ${formatCatClass(catClass)}`}</h3>
                <h4
                    className={`producttile__productname ${
                        isGreenLeaf ? 'producttile__productname__green-leaf' : ''
                    }`}>
                    {productName}
                </h4>
              </div>
            </a>
            {isGreenLeafEnabled() && (
              <div className="producttile__greenleaf-block">
                {isGreenLeaf && <GreenLeafTag greenLeafData={greenLeafData} />}
              </div>
            )}
            {daily ? (
              <div
                className="producttile__price"
                id={`${catClass}-price-description`}
              >
                {currencyToLocale(daily)}{" "}
                {intl.formatMessage({ id: "cart:per-day-text" })}
              </div>
            ) : null}
          </div>
          {isAvailableWithWarning && !!daily && isP2P && (
            <HigherFeeWarningAlert className="mb_1" productName={productName} />
          )}
          <div
            className={`${footerTileClassName} producttile__footer`}
            ref={footerTileRef}
          >
            {footerContent()}
            {isP2P && (
              <FavouritesButton
                isFavorite={isProductFavorite(catClass)}
                sku={catClass}
                qty={1}
                favoriteClasses={"prod_tile_fav_btn"}
                productName={productName}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(BaseProductTile);

BaseProductTile.defaultProps = {
  productImg: "",
  catClass: "",
  productName: "",
  productUrl: "",
  accountNumber: "",
  handleAnchorClick: () => {},
  handleRedirectClick: () => {},
  footerContent: () => {},
  productAltText: "",
  footerTileRef: () => {},
  footerTileClassName: "",
  productRatesDailyWeeklyMonthly: {},
  variant: PRODUCT_TILE_VARIANT.GRID_VIEW,
  specifications: "",
  greenLeafDetails: shape({
    isGreenLeaf: false,
    greenLeafData: ""
  })
};

BaseProductTile.propTypes = {
  productImg: string,
  catClass: string,
  productName: string,
  productUrl: string,
  accountNumber: oneOfType([number, string]),
  handleAnchorClick: func,
  handleRedirectClick: func,
  footerContent: func,
  productAltText: string,
  footerTileRef: func,
  footerTileClassName: string,
  productRatesDailyWeeklyMonthly: shape({
    daily: string,
    monthly: string,
    weekly: string,
  }),
  variant: string,
  specifications: string,
  greenLeafDetails: shape({
    isGreenLeaf: bool,
    greenLeafData: string
  })
};
