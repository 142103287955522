import { bool, func, string } from 'prop-types';
import React from 'react';
import WireIcon from '../../../../resources/images/wire.svg';
import Button from '../button';
import { convertPhoneNumberAsPerSbmChannel } from '../../utils/commonUtils';
import './wires.scss';

const Wires = props => {
    const { iconClass, wireWrapperclass, wireButtonText, handleWireClick, wireTitleText, showButton } = props;
    const phoneNumber = convertPhoneNumberAsPerSbmChannel('800-667-9328');
    return (
        <div className={`wire-wrap ${wireWrapperclass}`}>
            <WireIcon className={`wire-icon ${iconClass}`} />
            <h4 className="wire-title">{wireTitleText}</h4>
            <div className="wire-content">
                Please try again later or reach out to us at <a href={`tel:${phoneNumber}`}>{phoneNumber}</a> for immediate
                help.
            </div>
            {showButton && (
                <Button className="button button-primary button-block" buttonAriaLabel={`${wireButtonText}`} onClick={handleWireClick}>
                    {wireButtonText}
                </Button>
            )}
        </div>
    );
};

Wires.propTypes = {
    wireButtonText: string,
    href: string,
    wireTitleText: string,
    wireWrapperclass: string,
    iconClass: string,
    handleWireClick: func,
    showButton: bool
};

Wires.defaultProps = {
    wireButtonText: 'Continue exploring',
    href: '#',
    wireTitleText: 'Sorry! Our wires got crossed',
    wireWrapperclass: '',
    iconClass: '',
    handleWireClick: () => {
        window.location.href = '/';
    },
    showButton: true
};

export default Wires;
