import React from "react";
import { bool, func, object, shape, string } from "prop-types";
import { useIntl } from "react-intl";
import { formatCatClass } from "../../../utils/commonUtils";
import GreenLeafTag from "../../../atoms/greenLeafTag/GreenLeafTag";
import "./productTileListView.scss";
import { productTileDataLocator } from "../../addToCartTile/dataLocators";

const DesktopLayout = ({
  catClass,
  productName,
  productUrl,
  handleAnchorClick,
  footerSection,
  specificationSection,
  getProductImage,
  ariaLabel,
  greenLeafDetails
}) => {
  const intl = useIntl();
  const { isGreenLeaf } = greenLeafDetails || {};

  const imageSection = () => (
    <>
      <div
        data-testid={
          productTileDataLocator.productTile_productFavoriteIndicator
        }
        className="producttile__favourite"
      >
      </div>
      <a
        className="producttile__top"
        href={productUrl}
        onClick={handleAnchorClick}
        {...ariaLabel}
      >
        {getProductImage()}
      </a>
    </>
  );

  const descriptionSection = () => (
    <>
      <a
        className="producttile__top"
        href={productUrl}
        onClick={handleAnchorClick}
        aria-describedby={`${catClass}-price-description`}
      >
        <div>
          <h3 className="producttile__catclass eyebrow">
            {`${intl.formatMessage({
              id: "homepage:cat-class",
            })} ${formatCatClass(catClass)}`}
          </h3>
          <h4
            className={`producttile__productname ${
              isGreenLeaf ? 'producttile__productname__green-leaf' : ''
            }`}>
            {productName}
          </h4>
        </div>
      </a>
      {isGreenLeaf && <GreenLeafTag greenLeafData={greenLeafDetails?.greenLeafData} />}
      {specificationSection()}
    </>
  );

  return (
    <div className="desktop-container">
      <div className="image-container">{imageSection()}</div>
      <div className="description-container">{descriptionSection()}</div>
      <div className="footer-container">{footerSection()}</div>
    </div>
  );
};

export default React.memo(DesktopLayout);

DesktopLayout.defaultProps = {
  catClass: "",
  productName: "",
  productUrl: "",
  handleAnchorClick: () => {},
  footerSection: ()=>{},
  specificationSection: ()=>{},
  getProductImage: ()=>{},
  ariaLabel: {},
  greenLeafDetails: shape({
    isGreenLeaf: false,
    greenLeafData: ""
  })
};

DesktopLayout.propTypes = {
  catClass: string,
  productName: string,
  productUrl: string,
  handleAnchorClick: func,
  footerSection: func,
  specificationSection: func,
  getProductImage: func,
  ariaLabel: object,
  greenLeafDetails: shape({
    isGreenLeaf: bool,
    greenLeafData: string
  })
};

