import React, { useState } from 'react';
import { bool, string } from 'prop-types';
import { useIntl } from 'react-intl';
import Button from '../button';
import GreenLeafInfoModal from './GreenLeafInfoModal';
import GreenLeaf from '../../../../resources/images/green-leaf.svg';
import InfoIcon18 from '../../../../resources/images/info18.svg';
import './greenLeafTag.scss';
import { greenLeafDataLocator } from './dataLocator';

const GreenLeafTag = ({ greenLeafData, showInfoIconWithoutLabel }) => {
    const intl = useIntl();
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

    const toggleHandler = () => {
        setIsInfoModalOpen(!isInfoModalOpen);
    };

    return (
        <>
            <Button
                type="button"
                className="button green_leaf_btn"
                buttonAriaLabel={intl.formatMessage({ id: 'green-leaf-info-aria-label' })}
                dataTestid={greenLeafDataLocator.green_leaf_label_testId}
                onClick={toggleHandler}>
                {showInfoIconWithoutLabel ? (
                    <InfoIcon18 aria-hidden="true" tabIndex="-1" className="green_leaf_btn_icon" />
                ) : (
                    <>
                        <GreenLeaf aria-hidden="true" tabIndex="-1" />
                        <span className="green_leaf_btn_label">
                            {greenLeafData || intl.formatMessage({ id: 'green-leaf-tagging-label-text' })}
                        </span>
                    </>
                )}
            </Button>
            {isInfoModalOpen && <GreenLeafInfoModal isInfoModalOpen onCloseHandler={toggleHandler} />}
        </>
    );
};

export default React.memo(GreenLeafTag);

GreenLeafTag.defaultProps = {
    greenLeafData: '',
    showInfoIconWithoutLabel: false
};

GreenLeafTag.propTypes = {
    greenLeafData: string,
    showInfoIconWithoutLabel: bool
};
